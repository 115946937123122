import React, { useState, useEffect, useContext } from "react";
import "./ContactUs.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { RegxExpression } from "../../utils/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Common_Button from "../Common-Component/Common_Button/Common_Button";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Link } from "react-router-dom";
import Buttons from "../Common_Component/Buttons/Buttons";
import { Context } from "../../utils/context";
import classNames from "classnames";
import Success_Modalss from "../../Component/Common_Component/Success_Modal/Success_Modalss";
import { contactUs } from "../../utils/apis/common/Common";
import { GetAppSetup } from "../../utils/apis/master/Master";
const ContactUs = (props) => {


  const [mapSrc, setMapSrc] = useState("https://maps.google.com/maps?width=600&height=400&hl=en&q=pune&t=&z=14&ie=UTF8&iwloc=B&output=embed");
  const { IMG_URL, userdata, getData, postData, deleteData, sellerId, sellerDomain } = useContext(Context);
  const [showForm, setShowForm] = useState(false);
  const [showBg, setShowBg] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [showContactTextHolder, setShowContactTextHolder] = useState(true);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm();
  const handleMapTextClick = () => {
    setShowForm(true);
    setShowBg(true);
    setShowContactTextHolder(false);
  };

  const handleArrowClick = () => {
    setShowContactTextHolder(true);
    setShowForm(false);
    setShowBg(false);
  };

  const onSubmit = async (data) => {
    data.seller_id = sellerId;
    data.domain_id = sellerDomain;
    const res = await contactUs(data)
    if (res?.success) {
      reset();
      setSuccessModalShow(true);
      setTimeout(() => {
        setSuccessModalShow(false);
      }, 1000);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  const [appSetup, setAppSetup] = useState();
  const getAppSetup = async () => {
    const res = await GetAppSetup(sellerId);
    if (res?.success) {
      setAppSetup(res?.data);
      setMapSrc(`https://maps.google.com/maps?width=600&height=400&hl=en&q=${res?.data?.address}&t=&z=14&ie=UTF8&iwloc=B&output=embed`);
    }
  };

  useEffect(() => {
    getAppSetup();
  }, [sellerId]);
  return (
    <>
      <section className="contact-us">
        <div className="map-section">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                className="gmap_iframe"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src={mapSrc}
              ></iframe>
              <a href="https://pdflist.com/" alt="pdflist.com"></a>
            </div>
          </div>
        </div>
        {/* <div className="contact_form_holder "> */}
        <div
          className={`contact_form_holder ${showBg ? "contact_form_bg" : ""}`}
        >
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-lg-4 col-md-4">
                <div
                  className={`contact-text-holder ${showContactTextHolder ? "" : "d-none"
                    }`}
                >
                  <div className="heading-holder mb-4">
                    <h2>Contact us</h2>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-phone"
                      />
                    </div>
                    <div className="address-holder">
                      <p>{appSetup?.contact_no}</p>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-envelope"
                      />
                    </div>
                    <div className="address-holder">
                      <p>{appSetup?.email}</p>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-location-dot"
                      />
                    </div>
                    <div className="address-holder">
                      <p>
                        {" "}
                        {appSetup?.address}{" "}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-map-location-dot"
                      />
                    </div>
                    <div
                      className="address-holder map-text"
                      onClick={handleMapTextClick}
                    >
                      <p>Find Us on Map</p>
                    </div>
                  </div>

                  <div className="border-holder  my-4"></div>
                  <div className="social-icon">
                    <a href="https://www.facebook.com/" target="_blank"><img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/contact/facebook.png"
                      }
                      className="facebook-icon me-2"
                    /></a>
                    <a href="https://in.linkedin.com/" target="_blank"><img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/contact/linkedin.png"
                      }
                      className="facebook-icon me-2"
                    /></a>
                    <a href="https://in.pinterest.com/" target="_blank"><img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/contact/pinterest.png"
                      }
                      className="facebook-icon me-2"
                    /></a>
                    <a href="https://twitter.com/i/flow/login" target="_blank"><img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/contact/twitter.png"
                      }
                      className="facebook-icon"
                    /></a>
                  </div>
                </div>
              </div>
              <div
                className={`${showForm
                  ? "col-xl-5 col-lg-7 col-12 scale-down-hor-right"
                  : "col-lg-8 col-md-8 scale-up-hor-right"
                  }`}
              >
                <div className="form-holder ">
                  <div
                    className={`arrow-holder ${showForm ? "arrow-holder" : "d-none"
                      }`}
                    onClick={handleArrowClick}
                  >
                    {/* <FontAwesomeIcon className="left-arrow" icon="fa-solid fa-arrow-left" /> */}
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Icon/arrow-left.png"
                      }
                      className="left-arrow"
                    />
                  </div>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="form_container">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required"
                          })}
                          onKeyDown={(event) => {
                            if (!/[A-Z-a-z ]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          type="text"
                          placeholder="Your name"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1 "
                      >
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.email,
                          })}
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: RegxExpression.email,
                              message: "Invalid email address",
                            },
                          })}
                          type="email"
                          placeholder="Email" />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.subject,
                          })}
                          {...register("subject", {
                            required: "Subject is required"
                          })}
                          type="text" placeholder="Subject" />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.message,
                          })}
                          {...register("message", {
                            required: "Message is required"
                          })}
                          as="textarea"
                          placeholder="Your message"
                          rows={3}
                        />
                      </Form.Group>
                      <div className="btn-holder">
                        <Buttons
                          className="SubmitBtn"
                          text="Send Message"
                          padding="7px 20px"
                          onClick={handleSubmit(onSubmit)}
                        />
                      </div>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Success_Modalss show={successModalShow} texts={'Form Submitted Successfully'} />
    </>
  );
};

export default ContactUs;
