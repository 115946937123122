import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row, Tab } from "react-bootstrap";
import ProductCard from "../Common_Component/Product_Card/ProductCard";
import Nav from "react-bootstrap/Nav";
import "./CategoryPage.css";
import { Context } from "../../utils/context";
import EmptyCategory from "./EmptyCategory";
import { categorys } from "../../utils/apis/common/Common";
import { products } from "../../utils/apis/common/Common";
import {
  Products,
  Category,
  AllSubCategory,
  AllChildCategory,
} from "../../utils/apis/Product/Product";
import { Form } from "react-bootstrap";

import Buttons from "../Common_Component/Buttons/Buttons";
import { Swiper, SwiperSlide } from "swiper/react";
import Veggies_Section_Card from "../Common_Component/Veggies_Section_Card/Veggies_Section_Card";
import { ShimmerButton } from "react-shimmer-effects";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { ShimmerPostItem } from "react-shimmer-effects";
const CategoryPage = () => {
  const { IMG_URL, userdata, getData, deleteData, sellerId, type, sellerDomain } =
    useContext(Context);
  const [category, setCategory] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const [product, setProduct] = useState([]);
  const [subCategoryLoder, setSubCategoryLoder] = useState(false);
  const [childCategoryLoder, setChildcategoryLoder] = useState(false);
  const [productLoder, setProductLoder] = useState(false);
  const getProject = async (data) => {
    const res = await Category(sellerDomain, type);
    if (res?.success) {
      setCategory(res?.data);
      const storedCategory = localStorage.getItem("outer4_category_id");
      console.log(storedCategory, 'stored');
      if (storedCategory !== null) {
        setSubCategory([]);
        setChildCategory([]);
        getSubCategory(storedCategory, storedCategory);
      } else {
        getSubCategory(res?.data[0]?.category_id, res?.data[0]?.category_id);
      }
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [activeSubCategory, setActiveSubCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const getSubCategory = async (category_id, seller_cat_id) => {
    setSubCategoryLoder(true);
    setActiveCategory(category_id);
    const res = await AllSubCategory(sellerDomain, category_id);
    if (res?.success) {
      setSubCategory(res?.data);
      setSubCategoryLoder(false);
      if (res?.data?.[0]?.sub_category_id) {
        getChildCategory(
          res?.data?.[0]?.sub_category_id,
        );
        await getProduct(category_id, res?.data?.[0]?.sub_category_id);
      } else {
        await getProduct(category_id);
      }
    }
  };

  const [activeChildCategory, setActiveChildCategory] = useState("");
  const [childCategory, setChildCategory] = useState([]);
  const getChildCategory = async (sub_category_id) => {
    setChildcategoryLoder(true);
    setActiveSubCategory(sub_category_id);
    const res = await AllChildCategory(sellerDomain, sub_category_id);
    if (res?.success) {
      setChildCategory(res?.data);
      setChildcategoryLoder(false);
      console.log(res?.data);
      if (res?.data?.[0]?.child_category_id) {
        await getProduct(
          res?.data?.[0]?.category_id,
          res?.data?.[0]?.sub_category_id,
          res?.data?.[0]?.child_category_id,
        );
      } else {
        await getProduct(activeCategory, sub_category_id);
      }
    }
  };

  const [products, setProducts] = useState([]);
  const getProduct = async (
    category_id = "",
    sub_category_id = "",
    child_category_id = "",
  ) => {
    setProductLoder(true);
    const storedName = localStorage.getItem("outer5_prodName");
    const res = await Products(
      category_id,
      sub_category_id,
      child_category_id,
      sellerId,
      sellerDomain,
      storedName,
    );
    if (res?.success) {
      setProducts(res?.data);
      setProductLoder(false);
    }
  };

  useEffect(() => {
    getProject();
    // const storedCategory = localStorage.getItem("outer4_category_id");
    // if (storedCategory) {
    //   getProduct(storedCategory);
    // }
  }, [sellerDomain]);

  // useEffect(() => {
  //   getProject();
  //   const storedCategory = localStorage.getItem("outer4_category_id");
  //   if (storedCategory) {
  //     getProduct(storedCategory);
  //   }
  // }, [localStorage.getItem("outer4_category_id")]);

  const [swiperInstance, setSwiperInstance] = useState(null);
  const [activeHearts, setActiveHearts] = useState([]);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const handleMainSliderClick2 = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  return (
    <section className="category-page">
      <Container>
        <div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className="mt-3">
              <Col xxl={2} xl={2} lg={2}>
                <Nav variant="pills" className="flex-column nav-item-class">
                  <div className="mt-2">
                    <p className="categories-title">Categories</p>
                  </div>
                  {category.map((val) => (
                    <Nav.Item>
                      <Nav.Link
                        className={
                          activeCategory == val?.category_id ? "active" : ""
                        }
                        onClick={() =>
                          getSubCategory(val?.category_id, val?.category_id)
                        }
                      >
                        {val.name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>

              <Col xxl={10} xl={10} lg={10} md={10} sm={12} xs={12}>
                <div className="subcategorytabss">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="vegfirst"
                  >
                    <Row className="vegetable-sec">
                      <Col lg={3} md={4} sm={6} className=" mb-4">
                        <div className="bg-color">
                          <div className="product-name">
                            {subCategory.length > 0 && (
                              <p className="subcattt">Subcategory</p>
                            )}
                          </div>
                          <Nav variant="pills" className="flex-column">
                            {/* {subCategory?.map((value, index) => (
                              <Nav.Item>
                                <Nav.Link
                                  className={
                                    activeSubCategory == value?.sub_category_id
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() =>
                                    getChildCategory(
                                      value?.sub_category_id,
                                      value?.sub_category_id
                                    )
                                  }
                                >
                                  {value?.name}
                                </Nav.Link>
                              </Nav.Item>
                            ))} */}

                            {subCategoryLoder === false ? (

                              subCategory?.map((value, index) => (
                                <Nav.Item key={index}>
                                  <Nav.Link
                                    className={
                                      activeSubCategory === value?.sub_category_id
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() =>
                                      getChildCategory(
                                        value?.sub_category_id,
                                        value?.sub_category_id
                                      )
                                    }
                                  >

                                    {value?.name}
                                  </Nav.Link>
                                </Nav.Item>
                              ))

                            ) : (
                              <>
                                <ShimmerButton size="lg" />
                                <ShimmerButton size="lg" />
                                <ShimmerButton size="lg" />
                                <ShimmerButton size="lg" />
                              </>
                            )}
                          </Nav>
                        </div>
                      </Col>

                      <Col
                        lg={9}
                        md={8}
                        className="px-lg-0 px-md-0 px-sm-none px-none"
                      >
                        <Tab.Content className="subcat-tabcobt">
                          <Tab.Pane eventKey="vegfirst">
                            <Row>
                              <Col
                                lg={12}
                                md={12}
                                sm={12}
                                className="px-lg-0 px-md-0 px-sm-none px-none"
                              >
                                <Swiper
                                  breakpoints={{
                                    0: {
                                      slidesPerView: 1,
                                      spaceBetween: 0,
                                    },
                                    380: {
                                      slidesPerView: 2,
                                    },
                                    575: {
                                      slidesPerView: 3,
                                    },
                                    768: {
                                      slidesPerView: 3,
                                    },
                                    992: {
                                      slidesPerView: 4,
                                    },
                                    1200: {
                                      slidesPerView: 4,
                                    },
                                    1400: {
                                      slidesPerView: 4,
                                      spaceBetween: 0,
                                    },
                                  }}
                                  navigation={false}
                                  className="mySwiper"
                                  onSwiper={(swiper) =>
                                    setSwiperInstance(swiper)
                                  }
                                >
                                  {/* {childCategory?.map((value, index) => (
                                    <SwiperSlide>
                                      <Veggies_Section_Card child={value} product={getProduct} />
                                    </SwiperSlide>
                                  ))} */}
                                  {childCategoryLoder === false ? (
                                    // If subCategoryLoader is false, render the child categories
                                    <>
                                      {childCategory?.map((value, index) => (
                                        <SwiperSlide key={index}>
                                          <Veggies_Section_Card child={value} product={getProduct} />
                                        </SwiperSlide>
                                      ))}
                                    </>
                                  ) : (
                                    // If subCategoryLoader is true, render the shimmer buttons
                                    <>
                                      <ShimmerThumbnail height={250} rounded />
                                      <ShimmerThumbnail height={250} rounded />
                                      <ShimmerThumbnail height={250} rounded />

                                    </>
                                  )}
                                </Swiper>
                                {childCategory.length > 4 && (
                                  <div class="silder-btn">
                                    <div
                                      className="back-btn"
                                      onClick={() =>
                                        handleMainSliderClick("prev")
                                      }
                                    >
                                      {" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/Icon/left.png"
                                        }
                                      />{" "}
                                    </div>
                                    <div
                                      className="next-btn"
                                      onClick={() =>
                                        handleMainSliderClick("next")
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/Icon/right.png"
                                        }
                                      />{" "}
                                    </div>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Tab.Pane>

                          <Tab.Pane eventKey="vegsecond">
                            <Row>
                              <Col
                                lg={12}
                                md={12}
                                sm={12}
                                className="px-lg-0 px-md-0 px-sm-none px-none"
                              >
                                <Swiper
                                  breakpoints={{
                                    0: {
                                      slidesPerView: 1,
                                      spaceBetween: 0,
                                    },
                                    380: {
                                      slidesPerView: 2,
                                    },
                                    575: {
                                      slidesPerView: 3,
                                    },
                                    768: {
                                      slidesPerView: 3,
                                    },
                                    992: {
                                      slidesPerView: 4,
                                    },
                                    1200: {
                                      slidesPerView: 4,
                                    },
                                    1400: {
                                      slidesPerView: 4,
                                      spaceBetween: 0,
                                    },
                                  }}
                                  navigation={false}
                                  className="mySwiper"
                                  onSwiper={(swiper) =>
                                    setSwiperInstance(swiper)
                                  }
                                >
                                  <SwiperSlide>
                                    <Veggies_Section_Card />
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <Veggies_Section_Card />
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <Veggies_Section_Card />
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <Veggies_Section_Card />
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <Veggies_Section_Card />
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <Veggies_Section_Card />
                                  </SwiperSlide>
                                </Swiper>
                                <div class="silder-btn">
                                  <div
                                    className="back-btn"
                                    onClick={() =>
                                      handleMainSliderClick2("prev")
                                    }
                                  >
                                    {" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/Icon/left.png"
                                      }
                                    />{" "}
                                  </div>
                                  <div
                                    className="next-btn"
                                    onClick={() =>
                                      handleMainSliderClick2("next")
                                    }
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/Icon/right.png"
                                      }
                                    />{" "}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>

                <Tab.Content>
                  {productLoder === false ? (

                    <>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {products.data?.length > 0 ? (
                            <>
                              {products?.data?.map((item, index) => (
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                  key={index}
                                >
                                  <ProductCard
                                    images={item?.thumbnail}
                                    cardtitle={item?.product_title}
                                    cardtext={item?.cardtext}
                                    productId={item?.id}
                                  />
                                </Col>
                              ))}
                            </>
                          ) : (
                            <>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={12}
                                xs={12}
                                className="mx-auto"
                              >
                                <EmptyCategory />
                              </Col>
                            </>
                          )}
                        </Row>
                      </Tab.Pane>
                    </>
                  ) : (

                    <>
                      <ShimmerPostItem size="lg" />
                      <ShimmerPostItem size="lg" />
                      <ShimmerPostItem size="lg" />
                    </>
                  )}

                  {/* <Tab.Pane eventKey="second">
                                        <Row>
                                            <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} className='mx-auto' >
                                                <EmptyCategory />
                                            </Col>
                                        </Row>
                                    </Tab.Pane> */}
                  {/* <Tab.Pane eventKey="third">Second tab content</Tab.Pane> */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </section>
  );
};

export default CategoryPage;
