import React, { useState, useEffect, useContext } from "react";
import "./BrowseCatogary.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";
import { Category } from "../../../utils/apis/Product/Product";
function BrowseCatogary() {
  const { IMG_URL, userdata, getData, deleteData, sellerId, type, sellerDomain } =
    useContext(Context);
  console.log(sellerId);
  const [browseCat, setBrowseCategory] = useState([]);

  const getCategory = async () => {
    const res = await Category(sellerDomain, type);
    if (res?.success) {
      setBrowseCategory(res?.data);
      var category = [];
      var combine = [];
      var lastIndex = "";
      res?.data?.map((value, index) => {
        if (index == 0 || index == 3) {
          category.push(value);
        } else {
          combine.push(value);
          if (combine.length === 2) {
            category.push(combine);
            combine = [];
          }
        }
      });
      if (combine.length === 1) {
        category.push(combine);
        combine = [];
      }
      setBrowseCategory(category);
    }
  };

  console.log(browseCat, "lll");
  const navigate = useNavigate();
  const handleNavigate = async (id) => {
    localStorage.setItem("outer4_category_id", id);
    navigate("/category");
  };
  console.log(browseCat, "combine");
  useEffect(() => {
    getCategory();
  }, [sellerDomain]);
  return (
    <>
      <section className="browsecatogary_section">
        <Container>
          <div className="browsecatogry_main mb-5">
            <h2 className="hometitle mb-4">Browse by Category</h2>
            <div className="cardssmain">
              <Row>
                {browseCat?.map((category, index) => (
                  <>
                    {index === 0 && (
                      <Col xxl={4} xl={4} lg={4} md={6} sm={6}>
                        <Link to="/category">
                          <div
                            className="cardsimgmain"
                            onClick={() =>
                              handleNavigate(category?.category_id)
                            }
                          >
                            <div className="cardsbigimgdiv">
                              <img
                                src={IMG_URL + category?.image}
                                className="bbroswecardimg"
                                alt={category?.s_category?.name}
                              />
                            </div>
                            <div className="hoverovarlay"></div>
                            <div className="cardhead">
                              <h4 className="cardheadtext">{category.name}</h4>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    )}
                    {index === 1 && (
                      <Col xxl={4} xl={4} lg={4} md={6} sm={6}>
                        <Row>
                          {category?.map((sub, subindex) => (
                            <Col
                              key={category.id}
                              xxl={12}
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                            >
                              <Link to="/category">
                                <div
                                  className="cardsimgmain cardmainimg1"
                                  onClick={() =>
                                    handleNavigate(sub?.category_id)
                                  }
                                >
                                  <div className="cardsimgdiv">
                                    <img
                                      src={IMG_URL + sub.image}
                                      className="broswecardimg2"
                                      alt={sub.name}
                                    />
                                  </div>
                                  <div className="hoverovarlay"></div>
                                  <div className="cardhead">
                                    <h4 className="cardheadtext">{sub.name}</h4>
                                  </div>
                                </div>
                              </Link>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    )}
                    {index === 2 && (
                      <Col xxl={4} xl={4} lg={4} md={6} sm={6}>
                        <Link to="/category">
                          <div
                            className="cardsimgmain"
                            onClick={() =>
                              handleNavigate(category?.category_id)
                            }
                          >
                            <div className="cardsbigimgdiv">
                              <img
                                src={IMG_URL + category.image}
                                className="bbroswecardimg"
                                alt={category.name}
                              />
                            </div>
                            <div className="hoverovarlay"></div>
                            <div className="cardhead">
                              <h4 className="cardheadtext">{category.name}</h4>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    )}
                  </>
                ))}
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default BrowseCatogary;
