import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FirstRating from "./FirstRating/FirstRating";
import Reviews from "./Reviews/Reviews.js";
import "./Reviews/Review.css";
import { Context } from "../../../utils/context.js";
import { homeReview } from "../../../utils/apis/common/Common.js";

const RatingReviews = () => {
  const { IMG_URL, userdata, getData, deleteData, sellerId, sellerDomain } =
    useContext(Context);
  const [peoplescount, setPeoplescount] = useState([]);
  const getReviews = async () => {
    const res = await homeReview(sellerDomain);
    console.log(res, "setReviews");
    if (res?.success) {
      setPeoplescount(res?.data?.length);
    }
  };
  useEffect(() => {
    getReviews();
  }, [sellerDomain]);
  return (
    <section className="main-rating-review">
      <Container>
        <div className="title-common">
          <h3 className="hometitle mb-lg-5">Ratings And Reviews</h3>
        </div>
        <Row>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
            <FirstRating peoplescount={peoplescount} />
          </Col>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
            <Reviews />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RatingReviews;
