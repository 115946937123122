import React, { useState, useEffect, useContext } from "react";
import "./HappyCustomer.css";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../utils/context";
import { getHappyCustomers } from "../../../utils/apis/common/Common";

library.add(fas);
const HappyCustomer = () => {
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const [getReview, setReview] = useState();
  const { getData, IMG_URL, sellerId, sellerDomain } = useContext(Context);
  const getProject = async (data) => {
    const res = await getHappyCustomers(sellerDomain);
    if (res?.success) {
      setReview(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getProject();
  }, [sellerDomain]);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  return (
    <>
      <section className="happy-clients">
        <div className="container">
          <div className="row">
            <div class="text-center mb-5">
              <h3 class="hot-title">Happy Customers</h3>
              <div class="title-border"></div>
            </div>

            <Swiper
              spaceBetween={30}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination]}
              autoplay={{ delay: 300 }}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              className="mySwiper"
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                420: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                486: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 3,
                },
              }}
            >
              {getReview?.map((review, index) => (
                <SwiperSlide key={index}>
                  <div className="client-div mt-3 mb-5">
                    <Row>
                      <Col lg={3} md={4} sm={4} xs={4}>
                        <img
                          className="client-img"
                          src={IMG_URL + review?.image}
                          alt=""
                        />
                      </Col>
                      <Col lg={9} md={8} sm={8} xs={8}>
                        <h5 className="client-name">{review?.name}</h5>
                      </Col>
                    </Row>
                    <div className="mt-3">
                      <p className="client-review">
                        {htmlToReactParser.parse(review?.description)}
                      </p>
                      <div className="d-flex">
                        {Array.from({ length: review?.star || 0 }).map(
                          (_, i) => (
                            <div key={i}>
                              <FontAwesomeIcon
                                icon={["fas", "star"]}
                                className="star-icon mt-3"
                              />
                            </div>
                          )
                        )}
                        {Array.from({ length: 5 - review?.star }).map(
                          (_, i) => (
                            <div key={i}>
                              <FontAwesomeIcon
                                style={{ color: "grey" }}
                                icon={["fas", "star"]}
                                className="star-icon mt-3"
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div class="silder-btn">
              <div
                className="back-btn"
                onClick={() => handleMainSliderClick("prev")}
              ></div>
              <div
                className="next-btn"
                onClick={() => handleMainSliderClick("next")}
              ></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HappyCustomer;
