import React, { useEffect, useRef, useContext, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import "./InquiryForm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-bootstrap/Form";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../utils/context";
import { inquryformPost } from "../../../../utils/apis/common/Common";
import classNames from "classnames";
import { RegxExpression } from "../../../../utils/common";

const InquiryForm = (props) => {
  const { getData, IMG_URL, Select2Data, sellerId, sellerDomain } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    // data.seller_id = sellerId;
    data.domain_id = sellerDomain;
    data.s_product_id = props?.product;
    console.log(props?.product);
    const res = await inquryformPost(data);
    if (res?.success) {
      notify(true);
      reset();
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const notify = () => {
    toast("Inquiry Submitted Successfully!", { autoClose: 3000 });
    // Close the modal after 3 seconds
    setTimeout(() => {
      props.onHide(); // Call onHide function passed through props to close the modal
    }, 3000);
  };

  const toastRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toastRef.current && !toastRef.current.contains(event.target)) {
        // Click occurred outside the toast, close it
        toast.dismiss();
      }
      setValue("w_site_url", window.location.href);
    };

    // Attach event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Detach event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section className="modal_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inquiry-form"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter">
                      Modal heading
                  </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/inquryform/girlimg.png"
                  }
                  className="girl-imggggg"
                  alt="..."
                />
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Business Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="b_name "
                      placeholder="Enter Name"
                      {...register("b_name", {
                        required: "Name required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.b_name,
                        "is-valid": getValues("b_name "),
                      })}
                      onKeyDown={(event) => {
                        if (!RegxExpression.name.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Mobile Number*</Form.Label>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.contact_no,
                        "is-valid": getValues("contact_no"),
                      })}
                      type="text"
                      name="contact_no"
                      placeholder="Enter Mobile Number"
                      {...register("contact_no", {
                        required: "Field is required",
                        minLength: {
                          value: 10,
                          message: "Number should be at least 10 characters",
                        },
                      })}
                      onKeyDown={(event) => {
                        if (!RegxExpression.phone.test(event.key)) {
                          if (event.key !== "Backspace") {
                            event.preventDefault();
                          }
                        }
                      }}
                      maxLength={10}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Enter Email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: RegxExpression.email,
                          message: "Invalid email address",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.email,
                        "is-valid": getValues("email"),
                      })}
                    />
                  </Form.Group>
                  {/* <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Website URL</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      name="w_site_url"
                      placeholder="w_site_url"
                      {...register("w_site_url", {
                        required: "Website URL is required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.w_site_url,
                      })}
                    />
                  </Form.Group> */}
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      type="text"
                      name="message"
                      placeholder="message"
                      {...register("message", {
                        required: "message is required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.message,
                      })}
                    />
                  </Form.Group>
                  <div>
                    <button
                      className="inquiry-btn"
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Submit Enquiry
                    </button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Form>
          <div className="tonstify_section" ref={toastRef}>
            <ToastContainer />
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default InquiryForm;
